// Assets
import locationIcon from "../../../assets/location.png";
import phoneIcon from "../../../assets/phone.png";
import emailIcon from "../../../assets/email.png";
import "./index.scss";

const Contact = () => {
  return (
    <section className="contact">
      <div className="wrapper">
        <form className="contact-form">
          <h1>send us a message</h1>
          <input type="text" placeholder="Your Email Address" />
          <textarea placeholder="How Can We Help?"></textarea>
          <button>send</button>
        </form>
        <div className="contact-info">
          <div className="address">
            <img className="icon" src={locationIcon} alt="location" />
            <div className="column">
              <h1>Address</h1>
              <p>
             ՍԱՍՆԱ ԾՌԵՐ Փ. /  Տ / 83 ԴԱՎԹԱՇԵՆ 0054 ԵՐԵՎԱՆ ԵՐԵՎԱՆ ՀԱՅԱՍՏԱՆ                
              </p>
            </div>
          </div>
          </div>
      </div>
    </section>
  );
};

export default Contact;
