import { Route, Routes } from "react-router-dom";
// Pages
import HomePage from "../pages/HomePage";
import ProductsPage from "../pages/ProductsPage";
import ViewPage from "../pages/ViewPage";
import BasketPage from "../pages/BasketPage";
import ContactPage from "../pages/ContactPage";
import WishListPage from "../pages/WishListPage";
import CheckoutPage from "../pages/CheckoutPage";
// Auth
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/view/:id" element={<ViewPage />} />
      <Route path="/basket" element={<BasketPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/wish-list" element={<WishListPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      {/* <Route path="/sign-in" element={<LoginPage />} />
      <Route path="/sign-up" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} /> */}
    </Routes>
  );
};

export default Router;
