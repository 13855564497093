import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// Assets
import logoPicture from "../../assets/logo.webp";
import basketPicture from "../../assets/basket.png";
import heartPicture from "../../assets/heart.png";
import "./index.scss";

const Header = () => {
  const count = useSelector((state) => state.general.basketCount);

  return (
    <header className="header">
      <div className="wrapper">
        <div className="row">
          <div className="header-logo">
            {/* <img src={logoPicture} alt="logo" /> */}
          </div>
          <ul className="header-links">
            <Link to="/">Home</Link>
            <Link to="/products">Products</Link>
            <Link to="/about">About</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/contact">Contact</Link>
            {/* <Link to="/checkout">Checkout</Link> */}

          </ul>
        </div>
        <div className="header-tools">
          <Link to="/basket">
            <p>{count}</p>
            <div className="basket">
              <img src={basketPicture} alt="basket" />
            </div>
          </Link>
          <Link to="/wish-list">
            <div className="wish-list">
              <img src={heartPicture} alt="heart" />
            </div>
          </Link>
          {/* <Link to="/sign-in">
            <button className="sign-in">Sign in</button>
          </Link>
          <Link to="/sign-up">
            <button className="sign-up">Sign up</button>
          </Link> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
