import { memo, useCallback, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config/config";
import { isEmailValid, isPhoneValid } from "../../../validation";
import emailjs from "@emailjs/browser";
import resetForm from "../../../helpers/resetForm";
import "./index.scss";

const {
  emailConfig: { service_id, template_id, public_key },
} = config;

const CheckoutForm = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+374");
  const orders = JSON.parse(localStorage.getItem("bag"));

  // Email JS
  const submitData = useMemo(() => {
    let order = {};
    if (orders && orders.length !== 0) {
      order = orders[0];
    }
    const { title, price, currency } = order;
    return {
      from_email: email,
      from_phone: phone,
      from_orders: JSON.stringify(orders.map(order => order.title)),
    };
  }, [email, phone, orders]);

  const submitForm = useCallback(
    async (e) => {
      try {
        await emailjs.send(service_id, template_id, submitData, public_key);
        resetForm([setEmail, setPhone]);
        setPhone("+374");
      } catch (err) {
        throw new Error(err.message);
      }
    },
    [submitData]
  );

  const checkValidation = useCallback(() => {
    if (!isEmailValid(email)) {
      return { message: "Invalid Email!" };
    }
    if (!isPhoneValid(phone)) {
      return { message: "Invalid Phone!" };
    }
    if (!orders) {
      return { message: "You must choose order!" };
    }

    return { message: "Success send!", isOk: true };
  }, [email, phone, orders]);

  const handleChange = useCallback((event) => {
    const inputValue = event.target.value;

    // Check if the input value starts with the country code
    if (!inputValue.startsWith("+374")) {
      // If it doesn't, set the value to include the country code
      setPhone("+374");
    } else {
      setPhone(inputValue);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          const { message, isOk } = checkValidation();
          if (isOk) {
            toast.success(message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            submitForm();
          } else {
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }}
      >
        <h1>Checkout</h1>
        <div className="form-fields">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email"
          />
          <input
            value={phone}
            onChange={handleChange}
            type="text"
            placeholder="Phone"
          />
        </div>
        <button>Send</button>
      </form>
    </>
  );
};

export default memo(CheckoutForm);
