import axios from "axios";

export const SERVER_URL = "https://alwy-api.onrender.com/api";
export const UPLOAD_HEADER = {
  headers: { "Content-Type": "multipart/form-data" },
  
};

const $api = axios.create({
  baseURL: SERVER_URL,
  // withCredentials: true,
});

// Setting JWT Token.
$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

// Common
export const $POST = async (route, sendData, headers) => {
  try {
    return await $api.post(route, sendData, headers || {});
  } catch (err) {
    throw new Error(err.message);
  }
};

export const $GET = async (route) => {
  try {
    return await $api.get(route);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const $DELETE = async (route) => {
  try {
    return await $api.delete(route);
  } catch (err) {
    throw new Error(err.message);
  }
};

export default $api;
