import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addToBasket } from "../../../store/slices/general.slice";
import { $GET } from "../../../api";
import "./index.scss";

const View = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  const { title, price, currency, picture } = product;

  const addBag = useCallback(() => {
    const saved = JSON.parse(localStorage.getItem("bag")) || [];
    const bagCount = localStorage.getItem("bag-count");
    console.log(bagCount, "count");
    if (saved.length !== 0) {
      const result = saved.filter((product) => product.id === +id);
      if (result.length !== 0) return;
    }
    if (+bagCount !== 0 && !bagCount) {
      console.log(true);
      localStorage.setItem("bag-count", 0);
    }

    localStorage.setItem(
      "bag",
      JSON.stringify([
        ...saved,
        {
          ...product,
        },
      ])
    );
    console.log("ancav");
    localStorage.setItem("bag-count", +bagCount + 1);
  }, [product, id]);

  const checkBag = useCallback(() => {
    const saved = JSON.parse(localStorage.getItem("bag"));
    if (saved) {
      if (saved.length !== 0) {
        dispatch(addToBasket());
      }
    }
  });

  useEffect(() => {
    const get = async () => {
      const { data } = await $GET(`products/${id}`);
      setProduct(data);
    };
    get();
  });

  return (
    <section className="view">
      <div className="wrapper">
        <div className="view-picture">
          <img src={picture} alt="product" />
        </div>
        <div className="view-info">
          <h1 className="title">{title}</h1>
          <h4 className="price">
            {currency === "USD" ? "$" : undefined}
            {price}
          </h4>
          <div className="column">
            <select className="size-select">
              <option>S</option>
              <option>M</option>
              <option>L</option>
              <option>XL</option>
            </select>
            <select className="color-select">
              <option>White</option>
              <option>Black</option>
            </select>
          </div>
          <button
            onClick={() => {
              checkBag();
              addBag();
            }}
          >
            Add To Bag
          </button>
        </div>
      </div>
    </section>
  );
};

export default View;
