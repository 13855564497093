import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addProducts } from "./store/slices/products.slice";
import { $GET } from "./api";
import Router from "./router";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const get = async () => {
      try {
        const { data } = await $GET("/products");
        dispatch(addProducts(data));
      } catch (err) {
        throw new Error(err);
      }
    };
    get();
  }, [dispatch]);

  return <Router />;
};

export default App;
