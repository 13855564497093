import Table from "../components/sections/Basket/Table";
import CheckoutForm from "../components/forms/checkout-form";

const styles = {
  display: "flex",
  gap: "80px"
}

const CheckoutPage = () => {
  return (
    <div style={styles} className="center">
      <CheckoutForm />
      <Table />
    </div>
  );
};

export default CheckoutPage;
