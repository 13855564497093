import Layout from "../layout";
import Products from "../components/sections/Products";

const ProductsPage = () => {
  return (
    <Layout>
      <Products />
    </Layout>
  );
};

export default ProductsPage;
