import Layout from "../layout";
import WishList from "../components/sections/WishList";

const WishListPage = () => {
  return (
    <Layout>
      <WishList />;
    </Layout>
  );
};

export default WishListPage;
