const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const phoneRegex = /374(4[134]|55|77|88|9[134689])\d{6}/;

export function isEmailValid(email) {
  return email.match(emailRegex);
}

export function isPhoneValid(phone) {
  return phone.match(phoneRegex);
}
