import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basketCount: null,
  wishListCount: null,
  total: null,
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    addToBasket(state) {
      state.basketCount++;
    },
    setTotal(state, { payload }) {
      state.total = payload;
    },
  },
});

export const { addToBasket, setTotal } = slice.actions;
export default slice.reducer;
