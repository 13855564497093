import { configureStore } from "@reduxjs/toolkit";
// Slices
import productsReducer from "./slices/products.slice";
import authReducer from "./slices/auth.slice";
import generalReducer from "./slices/general.slice";

const store = configureStore({
  reducer: {
    products: productsReducer,
    auth: authReducer,
    general: generalReducer,
  },
});

export default store;
