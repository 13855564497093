import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// Assets
import heartPicture from "../../../assets/heart.png";
import "./index.scss";

const Products = () => {
  const products = useSelector((state) => state.products.data);

  const addWishList = useCallback((id, product) => {
    const saved = JSON.parse(localStorage.getItem("wish-list")) || [];
    if (saved.length !== 0) {
      const result = saved.filter((product) => product.id === +id);
      if (result.length !== 0) return;
    }
    localStorage.setItem(
      "wish-list",
      JSON.stringify([
        ...saved,
        {
          ...product,
        },
      ])
    );
  }, []);

  return (
    <section className="products">
      <div className="wrapper">
        <div className="products-tools"></div>
        <div className="products-list">
          {products?.map((product) => {
            const { id, title, picture, price, currency } = product;
            return (
              <div key={id} className="products-list-item">
                <Link to={`/view/${id}`}>
                  <img className="picture" src={picture} alt="model" />
                </Link>
                <div className="products-list-item-info">
                  <div className="texts">
                    <h4 className="title">{title}</h4>
                    <p className="price">
                      {price} {currency }
                    </p>
                  </div>

                  <img
                    onClick={() => {
                      addWishList(id, product);
                    }}
                    className="like-icon"
                    src={heartPicture}
                    alt="like-icon"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Products;
