import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTotal } from "../../../store/slices/general.slice";
import deleteProduct from "../../../helpers/deleteProduct";
import "./index.scss";
import deletePicture from "../../../assets/delete.svg";

const Table = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem("bag"));
    if (saved) {
      setData(saved);
    }
  }, []);
  // Calculate the total
  useEffect(() => {
    if (data.length !== 0) {
      let sum = 0;
      data.forEach((product) => {
        sum += +product.price;
      });
      dispatch(setTotal(sum));
    }
  }, [data, setTotal]);

  return (
    <table className="table">
      <tr>
        <th colSpan={2}>product</th>
        <th>price</th>
      </tr>
      {data ? (
        data.map((product) => {
          const { id, title, picture, price, currency } = product;
          console.log();
          return (
            <tr key={id}>
              <img src={picture} alt="model" />
              <td className="title">{title}</td>
              <td className="price">
                {price} {currency}
              </td>
              {/* <div className="counter">
                <div className="decrease">-</div>
                <div className="result">1</div>
                <div className="increase">+</div>
              </div> */}
              <td>
                <img
                  className="delete-icon"
                  onClick={() => {
                    const products = deleteProduct(id, "bag");
                    setData(products);
                  }}
                  src={deletePicture}
                  alt="x"
                />
              </td>
            </tr>
          );
        })
      ) : (
        <h4>There is no product</h4>
      )}
    </table>
  );
};

export default Table;
