import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "./Table";
import "./index.scss";

const Basket = () => {
  const total = useSelector((state) => state.general.total);

  return (
    <section className="basket">
      <div className="wrapper">
        <Table />
        <div className="checkout">
          <div className="column">
            <h1>cart totals</h1>
            {total && (
              <h3>
                Total: <b>{total} AMD</b>
              </h3>
            )}
          </div>
          <Link to="/checkout">
            <button>Checkout</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Basket;
