import { useCallback, useEffect, useState } from "react";
import deleteProduct from "../../../helpers/deleteProduct";
import "./index.scss";

const WishList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem("wish-list"));
    if (saved) {
      setData(saved);
    }
  }, []);

  const moveBag = useCallback((id, product) => {
    const saved = JSON.parse(localStorage.getItem("bag")) || [];
    if (saved.length !== 0) {
      const result = saved.filter((product) => product.id === +id);
      if (result.length !== 0) return;
    }
    localStorage.setItem(
      "bag",
      JSON.stringify([
        ...saved,
        {
          ...product,
        },
      ])
    );
  }, []);

  return (
    <section className="wish-list">
      <div className="wrapper">
        {data ? (
          data.map((product) => {
            const { id, title, picture, price, currency } = product;
            return (
              <div key={id} className="product">
                <div className="picture">
                  <img src={picture} alt="model" />
                </div>
                <h2 className="title">{title}</h2>
                <h4 className="price">
                  {currency === "USD" ? "$" : undefined}
                  {price}
                </h4>
                <button
                  onClick={() => {
                    moveBag(id, product);
                  }}
                >
                  Move to bag
                </button>
                <button
                  onClick={() => {
                    const products = deleteProduct(id, "wish-list");
                    setData(products);
                  }}
                  className="delete-button"
                >
                  x
                </button>
              </div>
            );
          })
        ) : (
          <h4>There is no product</h4>
        )}
      </div>
    </section>
  );
};

export default WishList;
